import MyCard from 'components/shared/Card/Card';
import CustomDialog from 'components/shared/Dialog/CustomDialog';
import TableSkeleton from 'components/shared/Skeletons/TableSkeleton';
import theme from 'components/utils/theme';
import { memo, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'rsuite';
import { analyticsActions } from '../../../redux/slice/analytics.slice';
import { GET_ANOMALIES } from '../../../redux/types/multi.types';
import CustomLegend from './CustomLegend';
import GraphTitle from './GraphTitle';
import { anomalyRangeOptions } from './helper';

const ScatterChart = () => {

    const dispatch = useDispatch();
    const [selectedRange, setSelectedRange] = useState('1year');
    const [loading, setLoading] = useState(false); // Add a loading state
    const anomaliesData = useSelector(analyticsActions.selectors.getAnomalies);

    useEffect(() => {
        const handleChartData = (range) => {
            setLoading(true);
            const payload = {
                range
            }

            dispatch({
                type: GET_ANOMALIES,
                payload,
            });
        }
        handleChartData(selectedRange)
    }, [dispatch, selectedRange]);

    useEffect(() => {
        if (anomaliesData) {
            setLoading(false)
        }
    }, [anomaliesData])

    const arr = [
        {
            label: "Critical Alerts",
            color: theme.palette.error.main
        },
        {
            label: "Moderate Alerts",
            color: theme.palette.warning.main
        },
        {
            label: "No Alerts",
            color: theme.palette.success.main
        },

    ];

    const options = {
        chart: {
            type: 'scatter',
            toolbar: {
                show: false,
            },
            zoom: {
                type: 'xy',
            },
        },

        legend: {
            show: false,
        },
        colors: [theme.palette.error.main, theme.palette.warning.main, theme.palette.success.main],
        grid: {
            show: true,
            borderColor: '#e0e0e0',
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        annotations: {
            shapes: [
                {
                    type: 'line',
                    x1: 'Feb',
                    y1: 30,
                    x2: 'Jul',
                    y2: 90,
                    borderColor: '#0000FF',
                    strokeWidth: 2,
                    opacity: 1,
                    label: {
                        text: 'Central Line',
                        position: 'center',
                        style: {
                            color: '#0000FF',
                            background: '#ffffff',
                        },
                    },
                },
            ],
        },
    };

    const series = useMemo(() => {
        return [
            {
                name: 'Critical Alerts',
                data: anomaliesData?.high,
                color: theme.palette.error.main,
                markers: {
                    shape: 'circle',
                    size: 8,
                },
            },
            {
                name: 'Moderate Alerts',
                data: anomaliesData?.medium,
                color: theme.palette.warning.main,
                markers: {
                    shape: 'triangle',
                    size: 8,
                },
            },
            {
                name: 'No Alerts',
                data: anomaliesData?.low,
                color: theme.palette.success.main,
                markers: {
                    shape: 'square',
                    size: 8,
                },
            },
        ];
    }, [anomaliesData]);

    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleToggleScreen = () => {
        setIsFullScreen((prevState) => {
            return !prevState;
        });
    };

    return (
        <>
            <MyCard height={450}>
                <GraphTitle
                    setTime={setSelectedRange}
                    time={selectedRange}
                    setSelectedRange={setSelectedRange}
                    showFullScreenBtn={true}
                    handleClickFullScreen={handleToggleScreen}
                    title={'AI-Driven Anomaly Detection'}
                    menuOptions={anomalyRangeOptions}
                />
                {loading ? (
                    <TableSkeleton rows={5} cols={5} />
                ) : (
                    <Chart options={options} series={series} type="scatter" height={325} />
                )}
                <CustomLegend arr={arr} />
            </MyCard>
            <CustomDialog fullScreen={true} title={'AI-Driven Anomaly Detection'} isOpen={isFullScreen} handleToggle={handleToggleScreen} maxWidth={'xl'}>
                <Grid xs={12} height={'100%'}>
                    {loading ? (
                        <TableSkeleton rows={4} cols={5} />
                    ) : (
                        <Chart options={options} series={series} type="scatter" height={'490px'} />
                    )}
                    <CustomLegend arr={arr} />
                </Grid>
            </CustomDialog>
        </>
    );
};

export default memo(ScatterChart);
