import { Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setUpFimSocket,
  unSubscribeSocketTopics,
} from "../../../redux/saga/socket";
import { fimActions } from "../../../redux/slice/fim.slice";
import { GET_FIM } from "../../../redux/types/shared.types";
import CustomTitle from "../../shared/CustomTitle";
import MyTable from "../../shared/Tables/MyTable/MyTable";
import MyTableToolbar from "../../shared/Tables/MyTable/MyTableToolbar";
import table from "../../shared/Tables/table.module.scss";
import text from "../../shared/css/text.module.scss";
import theme from "../../utils/theme";
import DeviceGraph from "../Device/DeviceGraph";
import DeviceStats from "../Device/DeviceStats";
import DetailChartCard from "../SingleDashboard/DetailChartCard";
import { fimIntegrityColumns, getFileDetailItems } from "./helper";
import { format } from "date-fns";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";

const Fim = () => {
  const fimData = useSelector(fimActions.selectors.getFim);
  const [searchText, setSearchText] = useState("");
  const [filteredFimData, setFilteredFimData] = useState(fimData);

  const eventCounts = useMemo(() => {
    if (!fimData?.length) return {};
    return fimData.reduce((counts, item) => {
      const event = item?.event;
      if (event) {
        counts[event] = (counts[event] || 0) + 1;
      }
      return counts;
    }, {});
  }, [fimData]);

  const fileDetails = useMemo(() => {
    return getFileDetailItems(eventCounts);
  }, [eventCounts]);

  const graphColors = [
    theme.palette.success.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main,
  ];
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const payload = {
        deviceId: id,
      };
      dispatch({ type: GET_FIM, payload });
    }
  }, [dispatch, id]);

  useEffect(() => {
    setUpFimSocket(dispatch);
    return () => {
      unSubscribeSocketTopics(["FIM"]);
    };
  }, [dispatch]);

  useEffect(() => {
    filterData(searchText);
  }, [fimData, searchText]);

  const filterData = (value) => {
    const filteredData = fimData.filter(
      (fim) =>
        fim?.event?.toLowerCase().includes(value) ||
        fim?.path?.toLowerCase().includes(value) ||
        (fim?.timestamp
          ? format(new Date(fim.timestamp), "d MMMM, yyyy h:mmaaa")
          : ""
        )
          .toLowerCase()
          .includes(value)
    );
    setFilteredFimData(filteredData);
  };

  const handleSearchChange = (event) => {
    setSearchText(event);
    filterData(event);
  };

  return (
    <Grid container>
      {/* <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                <CustomNavTabs selectedTab={selectedFimTab} handleSelectedTab={handleSelectedFimTab} navitems={fimNavItems} />
            </Grid> */}
      <Grid container spacing={2} marginTop={"1vh"} alignItems="center">
        <Grid item xs={12} sm={true}>
          <Typography className={text.heading1}>
            File Integrity Events
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={'auto'} display="flex" justifyContent="flex-end" gap={2}>
                    <Button startIcon={<RefreshIcon />} className={buttons.contained} variant="outlined">
                        Refresh
                    </Button>
                    <Button startIcon={<ColumnIcon />} className={buttons.outlined} variant="outlined">
                        Edit Columns
                    </Button>
                    <Button startIcon={<ExportIcon />} className={buttons.outlined} variant="outlined">
                        Export
                    </Button>
                    <Button startIcon={<EditIcon />} className={buttons.outlined} variant="outlined">
                        Edit
                    </Button>
                </Grid> */}
      </Grid>
      <Grid
        my={"15px"}
        container
        sx={{ justifyContent: { xl: "space-between", lg: "center" } }}
        spacing={2}
      >
        <Grid item xs={12} md={6} xl={6}>
          <DetailChartCard
            details={fileDetails}
            title={"Files Events"}
            chartSeries={{
              a: eventCounts?.created || 0,
              b: eventCounts?.deleted,
              c: eventCounts?.modified,
              d: eventCounts?.moved,
            }}
            totalValue={fimData?.length}
            totalText={"Total"}
            left={"35%"}
            graphColors={graphColors}
            title2={""}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <DeviceGraph />
        </Grid>
      </Grid>
      <Grid mb={2} item xs={12} xl={6} className={table.tableContainer}>
        <CustomTitle title={"Total File Integrity"} count={fimData?.length} />
        <Card>
          <MyTableToolbar
            placeHolder={"Search for files"}
            btn1Txt={false}
            onSearch={handleSearchChange}
            onDownload={() => handleDownloadCsv(filteredFimData, fimIntegrityColumns)}
            onPrint={() => handlePrintCsv(filteredFimData, fimIntegrityColumns)}
          />
          <MyTable
            checkboxSelection={true}
            columns={fimIntegrityColumns}
            rows={filteredFimData}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Fim;
