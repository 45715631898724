import { ContentPasteOff } from '@mui/icons-material';
import { Box } from '@mui/material';
import theme from 'components/utils/theme';
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import styles from '../../shared/Tables/MyTable/myTable.module.scss';

const ThreatDetectionChart = ({ threatsData }) => {
    console.log("threatsData", threatsData);

    // Memoize categories and series to avoid recalculating them on each render
    const categories = useMemo(() => {
        if (Array.isArray(threatsData?.high)) {
            return threatsData.high.map((item) => item.x); // x values are assumed to be the same for all levels
        }
        return []; // Default to empty array if data is not available
    }, [threatsData]);

    const series = useMemo(() => {
        if (Array.isArray(threatsData?.high)) {
            return [
                {
                    name: 'High Level Threats',
                    data: threatsData.high.map((item) => item.y), // High-level threats data
                },
                {
                    name: 'Medium Level Threats',
                    data: Array.isArray(threatsData.medium) ? threatsData.medium.map((item) => item.y) : [], // Medium-level threats data
                },
                {
                    name: 'Low Level Threats',
                    data: Array.isArray(threatsData.low) ? threatsData.low.map((item) => item.y) : [], // Low-level threats data
                },
            ];
        }
        return []; // Default to empty array if data is not available
    }, [threatsData]);

    // Memoize the calculation of the max Y value
    const maxYValue = useMemo(() => {
        const allValues = [
            ...(Array.isArray(threatsData?.high) ? threatsData.high.map((item) => item.y) : []),
            ...(Array.isArray(threatsData?.medium) ? threatsData.medium.map((item) => item.y) : []),
            ...(Array.isArray(threatsData?.low) ? threatsData.low.map((item) => item.y) : []),
        ];
        return allValues.length ? Math.max(50, Math.max(...allValues)) : 50; // Ensure a minimum value of 50
    }, [threatsData]);

    const options = useMemo(() => ({
        chart: {
            type: 'area',
            toolbar: {
                show: false,
            },
        },
        colors: [
            theme.palette.error.main,   // Color for high-level threats
            theme.palette.warning.light, // Color for medium-level threats
            theme.palette.success.main  // Color for low-level threats
        ],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0,
                stops: [0, 100],
            },
        },
        xaxis: {
            type: 'category', // Use category to show day names
            categories: categories, // Use the x values (day names) from data
        },
        yaxis: {
            min: 0,
            max: maxYValue, // Adjust max dynamically
            labels: {
                formatter: (value) => Math.round(value),
            },
        },
        grid: {
            borderColor: theme.palette.gray.light,
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true, // Show vertical lines
                },
            },
            yaxis: {
                lines: {
                    show: true, // Show horizontal lines
                },
            },
        },
        tooltip: {
            x: {
                format: 'dd MMM', // You can customize the format if needed
            },
        },
    }), [categories, maxYValue]); // Dependencies: categories and maxYValue

    // Show the chart only if we have valid data
    return (
        <div>
            {series.length > 0 ? (
                <Chart options={options} series={series} type="area" height={375} />
            ) : (
                <Box className={styles.noData}><ContentPasteOff /> No Data Available</Box>
            )}
        </div>
    );
};

export default ThreatDetectionChart;
