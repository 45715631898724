import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    userRoles: {},
    highRiskDevices: [],
    anomalies: [],
    threats: {}
}

const name = 'analytics'
const parentSelector = state => state?.[name]

const analyticsSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetanalytics: () => {
            return initialState;
        },
        setUserRoles: (state, action) => {
            return {
                ...state,
                userRoles: action.payload,
            };
        },
        setHighRiskDevices: (state, action) => {
            return {
                ...state,
                highRiskDevices: action.payload,
            };
        },
        setAnomalies: (state, action) => {
            return {
                ...state,
                anomalies: action.payload,
            };
        },
        setThreats: (state, action) => {
            return {
                ...state,
                threats: action.payload,
            };
        },

    },
});

const getUserRoles = createSelector(parentSelector, state => {
    return state?.userRoles
});

const getHighRiskDevices = createSelector(parentSelector, state => {
    return state?.highRiskDevices
});

const getAnomalies = createSelector(parentSelector, state => {
    return state?.anomalies
});

const getThreats = createSelector(parentSelector, state => {
    return state?.threats
});
const actions = {
    ...analyticsSlice.actions
}

const selectors = {
    getUserRoles,
    getHighRiskDevices,
    getAnomalies,
    getThreats
}

export const analyticsActions = { actions, selectors }

export default analyticsSlice;