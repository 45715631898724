import dayjs from "dayjs";
import AppsIcon from "../../static/svgs/Apps";
import DevicesIcon from "../../static/svgs/Devices";
import GroupsIcon from "../../static/svgs/Groups";
import NetworkIcon from "../../static/svgs/Network";
import ProfileIcon from "../../static/svgs/Profile";
import SettingsIcon from "../../static/svgs/Settings";

export const menuItems = [
  { label: "Dashboard", link: "/dashboard", icon: AppsIcon },
  { label: "Access Management", link: "/access-mangement", icon: ProfileIcon },
  { label: "Devices", link: "/", icon: DevicesIcon },
  { label: "Apps", link: "/apps", icon: GroupsIcon },
  // { label: 'Data', link: '/', icon: DataIcon },
  { label: 'Networks', link: '/networks', icon: NetworkIcon },
  // { label: 'SOUR', link: '/', icon: SourIcon },
  // { label: 'User & Entity Behavior', link: '/', icon: EntityIcon },
  { label: "Setting", link: "/account", icon: SettingsIcon },
];

export const deviceNavItems = [
  { label: "Device Inventory Management" },
  { label: "Device Compliance Management" },
  { label: "Real-time Device Inspection" },
  { label: "Remote Access Control" },
  { label: "Automated Patch Management" },
  { label: "Unified Endpoint Management" },
  { label: "EDR & XDR" },
];

export const dashboardNavItems = [
  { label: "Dashboard" },
  { label: "Apps" },
  { label: "FIM" },
  { label: "Files" },
  { label: "Alerts" },
  { label: "Users" },
];

export const insertIdToArray = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return [];
  }
  const newArray = arr.map((item, index) => ({
    id: index,
    ...item,
  }));
  return newArray;
};


export const commandTypes = {
  PORT: "PORT",
  PROCESS: "PROCESS",
  CONSOLE: "CONSOLE",
  UN_INST_APP: "UN_INST_APP",
};

export const splitFileName = (fileName) => {
  if (typeof fileName !== "string" || fileName.length === 0) {
    return ["", ""];
  }

  // Find the last period in the file name
  const lastDotIndex = fileName.lastIndexOf(".");

  // If no period is found, return the original file name and an empty string for the extension
  if (lastDotIndex === -1) {
    return [fileName, ""];
  }

  // Extract the base file name and the extension
  const baseFileName = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex + 1);

  // If the file name ends with a dot, treat it as no extension
  if (baseFileName.length === 0 || extension.length === 0) {
    return [fileName, ""];
  }

  return [baseFileName, extension];
};

export const formatFileSize = (sizeInBytes) => {
  if (typeof sizeInBytes !== "number" || sizeInBytes < 0) {
    console.error("Invalid input: sizeInBytes must be a non-negative number");
    return;
  }

  const KB = 1024;
  const MB = KB * 1024;
  const GB = MB * 1024;

  if (sizeInBytes >= GB) {
    return (sizeInBytes / GB).toFixed(2) + " GB";
  } else if (sizeInBytes >= MB) {
    return (sizeInBytes / MB).toFixed(2) + " MB";
  } else if (sizeInBytes >= KB) {
    return (sizeInBytes / KB).toFixed(2) + " KB";
  } else {
    return sizeInBytes + " Bytes";
  }
};

export const filterItemsById = (array1, array2) => {
  return array1?.filter((item) => array2?.includes(item?._id));
};

export const getMenuItems = (role) => {
  if (role !== "admin") {
    return menuItems.filter((item) => item.label !== "Access Management");
  }

  return menuItems;
};


export const formatDateBdata = (date) => {
  if (!date) {
    return ""
  } else {
    return dayjs(date).format("MM-DD-YYYY h:mm A")
  }
}