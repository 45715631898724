import { IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import DevicesIcon from "../../../static/svgs/Devices";
import LeftArrowIcon from "../../../static/svgs/LeftArrow";
import StatsIcon from "../../../static/svgs/Stats";
import TimeIcon from "../../../static/svgs/time";
import text from "../../shared/css/text.module.scss";
import theme from "../../utils/theme";
import CustomButton from "../Buttons/CustomButton";
import DrawerAccordion from "./DrawerAccordion";
import { drawerPaperStyles } from "./helper";
import tables from "./table.module.scss";

function formatTimeAgo(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();
  const difference = now.getTime() - date.getTime();

  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    return `${hours}h ${minutes % 60}min ago`;
  } else if (minutes > 0) {
    return `${minutes}min ago`;
  } else {
    return `${seconds}s ago`;
  }
}

export default function CustomDrawer({
  isDrawerOpen,
  selectedRow,
  toggleDrawer,
  handleDetailClick,
}) {
  return (
    <Drawer
      open={isDrawerOpen}
      variant="persistent"
      anchor="right"
      sx={drawerPaperStyles}
      className={tables.drawer}
    >
      <div className={tables.drawerContainer}>
        <div className={tables.upper}>
          <div className={tables.deviceInfo}>
            <div>
              <DevicesIcon fill={theme.palette.primary.main} />
              <div>
                <p className={text.bold1}>{selectedRow?.deviceName}</p>
                <p className={text.info2}>{selectedRow?.systemDetails?.ip}</p>
              </div>
            </div>
            <IconButton onClick={toggleDrawer}>
              <LeftArrowIcon />
            </IconButton>
          </div>
          <div className={tables.deviceInfo}>
            <div>
              <StatsIcon fill={theme.palette.primary.main} />
              <div>
                <p className={text.bold1}>Status</p>
                <p className={text.info2}>{selectedRow?.status}</p>
              </div>
            </div>
            <div>
              <TimeIcon fill={theme.palette.primary.main} />
              <div>
                <p className={text.bold1}>Last Seen</p>
                <p className={text.info2}>
                  {formatTimeAgo(selectedRow?.lastActive)}
                </p>
              </div>
            </div>

          </div>
          <div className={tables.deciceScroll}>
            <div className={tables.itemBox}>
              <p className={text.bold1}>Device Monitoring</p>
              <div className={tables.itemInfo}>
                <div>
                  <p className={text.bold1}>Alerts</p>
                  <p className={text.info2}>{selectedRow?.alertsCount || 0}</p>
                </div>
                <div className="flex-col-center">
                  <p className={text.bold1}>Anomalies</p>
                  <p className={text.info2}>{selectedRow?.anomalyCount || 0}</p>
                </div>
              </div>
            </div>
            <div className={tables.lastItemBox}>
              <DrawerAccordion device={selectedRow} ip={selectedRow?.systemDetails?.ip} />
            </div>
          </div>
        </div>
        <div className={tables.viewDetail}>
          <CustomButton
            onClick={() => handleDetailClick(selectedRow)}
            label={"View Full Details"}
            Icon={false}
            bgColor={theme.palette.primary.main}
            color={theme.palette.white.main}
            hover={theme.palette.primary.main}
            padding="10px"
          />
        </div>
      </div>
    </Drawer>
  );
}
