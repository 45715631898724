import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React from "react";
import forms from "./forms.module.scss";
import SearchIcon from "../../../static/svgs/search";

function SearchTextField({ onSearch, variant = "standard", placeholder }) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  const handleSearchClick = () => {
    onSearch(searchTerm);
  };

  return (
    <TextField
      value={searchTerm}
      onChange={handleChange}
      variant={variant}
      fullWidth={true}
      margin="normal"
      size="small"
      className={forms.searchBox}
      placeholder={placeholder}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchTextField;
