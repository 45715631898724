import MyBadge from "components/shared/MyBadge";
import { getBadge } from "components/shared/Tables/helper";
import theme from "components/utils/theme";
import dayjs from "dayjs";

export const highRiskColumns = [
    {
        field: "deviceId",
        headerName: "Device Id",
        minWidth: 100,
        flex: 1,
    },
    {
        field: "os",
        headerName: "OS",
        minWidth: 100,
        flex: 1,
    },
    {
        field: "ip",
        headerName: "IP",
        minWidth: 100,
        flex: 1,
    },
    {
        field: "alertCount",
        headerName: "No of Alerts",
        minWidth: 100,
        flex: 1,
    },
    {
        field: "lastActive",
        headerName: "Last Active",
        minWidth: 100,
        flex: 1.2,
        renderCell: (params) => dayjs(params?.row?.lastActive).format('DD-MM-YYYY hh:mm:ss a'),

    },
    {
        field: "status",
        headerName: "Status",
        minWidth: 100,
        flex: 1,
        renderCell: (params) => getBadge(params?.row?.status),

    }
];

export const getAccessBadge = (type) => {
    switch (type) {
        case 'Excessive Privilege':
            return <MyBadge
                title={"Excessive"}
                bgColor={theme.palette.error.main}
                txtColor={theme.palette.white.main}
            />
        case "Moderate Privilege":
            return <MyBadge
                title={"Moderate"}
                bgColor={theme.palette.warning.main}
                txtColor={theme.palette.white.main}
            />
        case "Least Privilege":
            return <MyBadge
                title={"Least"}
                bgColor={theme.palette.success.main}
                txtColor={theme.palette.white.main}
            />
        default:
        //someting
    }

};

export const WarningIcon = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33179 8.57143V4.28571C7.33179 3.89286 7.63196 3.57143 7.99883 3.57143C8.36569 3.57143 8.66586 3.89286 8.66586 4.28571V8.57143C8.66586 8.96429 8.36569 9.28571 7.99883 9.28571C7.63196 9.28571 7.33179 8.96429 7.33179 8.57143ZM7.99883 10C7.44519 10 6.99827 10.4786 6.99827 11.0714C6.99827 11.6643 7.44519 12.1429 7.99883 12.1429C8.55246 12.1429 8.99938 11.6643 8.99938 11.0714C8.99938 10.4786 8.55246 10 7.99883 10ZM15.7231 13.4857C15.2562 14.45 14.2756 15 13.0483 15H2.95604C1.72202 15 0.748149 14.45 0.281224 13.4857C-0.192371 12.5143 -0.0589636 11.2714 0.614742 10.2286L5.97771 1.14286C6.4513 0.414286 7.19838 0 7.99883 0C8.79927 0 9.54635 0.414286 9.99993 1.12143L15.3896 10.2429C16.0633 11.2857 16.19 12.5214 15.7164 13.4857H15.7231ZM14.289 11.0429C14.289 11.0429 14.2756 11.0286 14.2756 11.0143L8.89265 1.90714C8.69921 1.61429 8.36569 1.42857 7.99883 1.42857C7.63196 1.42857 7.29844 1.61429 7.09166 1.93571L1.72202 11.0143C1.30846 11.6429 1.21507 12.3286 1.45521 12.8214C1.68867 13.3071 2.2223 13.5714 2.94937 13.5714H13.0349C13.762 13.5714 14.2956 13.3071 14.5291 12.8214C14.7692 12.3286 14.6758 11.6429 14.2823 11.0429H14.289Z" fill="#00B0DF" />
        </svg>
    );
};

export const generateStatusChartData = (devices) => {
    const chartData = {
        Registered: 0,
        Activated: 0,
        Connected: 0,
        Disconnected: 0,
    };

    // Iterate over the devices array and count each status
    devices.forEach(device => {
        const status = device.status;
        if (chartData.hasOwnProperty(status)) {
            chartData[status]++;
        }
    });
    console.log("chart", chartData)
    return chartData;
}


export const processChartData = (alerts) => {
    const cData = { a: 0, b: 0, c: 0 };

    const alertMapping = {
        High: 'a',
        Medium: 'b',
        Low: 'c'
    };

    if (Array.isArray(alerts) && alerts.length > 0) {
        alerts.forEach(item => {
            const key = alertMapping[item?.name];

            // Only process if key exists and item.data is a valid array
            if (key && Array.isArray(item?.data)) {
                cData[key] = item.data.reduce((sum, data) => sum + (data || 0), 0); // Safeguard against invalid data
            }
        });
    }
    // Calculate total sum
    const total = cData.a + cData.b + cData.c;
    const newCData = { a: 0, b: 0, c: 0 }
    // Convert to percentages, guarding against division by zero
    if (total > 0) {
        newCData.a = ((cData.a / total) * 100).toFixed(2);
        newCData.b = ((cData.b / total) * 100).toFixed(2);
        newCData.c = ((cData.c / total) * 100).toFixed(2);
    } else {
        newCData.a = 0;
        newCData.b = 0;
        newCData.c = 0;
    }

    return {
        chartData: cData,
        total
    };
}


export const anomalyRangeOptions = [
    { value: '1year', label: '1 Year' },
    { value: '1month', label: '1 Month' },
    { value: '7days', label: '7 Days' },
]