import { Box, Grid, Typography } from '@mui/material';
import { Fragment, useMemo } from 'react';
import MyCard from '../../shared/Card/Card';
import ColoredIcon from '../../shared/ColoredIcon';
import text from '../../shared/css/text.module.scss';
import shared from '../../shared/shared.module.scss';
import { generateStatusChartData } from '../MultiDashboard/helper';
import { getStateItems } from './helper';
const DeviceStats = ({ devices }) => {

    const stateItems = useMemo(() => {
        const chartData = generateStatusChartData(devices);

        return getStateItems(chartData)

    }, [devices]);


    return (
        <MyCard height={'350px'}>
            <Grid height={'100%'} container >
                {stateItems.map((item, index) => (
                    <Fragment key={index}>
                        <Grid item xs={6} display={'flex'} alignItems={'center'}>
                            <ColoredIcon bgColor={item?.color} icon={item?.icon} />
                            <Box display={'flex'} flexDirection={'column'} gap={1}>
                                <Typography className={text.heading2} >
                                    {item.label}
                                </Typography>
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                    <Typography className={text.heading2} sx={{ color: item?.color }}>
                                        {item.count}
                                    </Typography>
                                    <div style={{ backgroundColor: item?.color }} className={shared.statsBadge}></div>
                                </Box>
                            </Box>
                        </Grid>
                        {/* {index % 2 !== 0 && index !== stateItems.length - 1 && (
                            <Grid item xs={12}>
                                <Divider className={styles.statsDivider} />
                            </Grid>
                        )} */}
                    </Fragment>
                ))}

            </Grid>
        </MyCard>
    );
};

export default DeviceStats;
