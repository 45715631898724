import apiInterceptor from "./Interceptors/ApiInterceptor";

export const getMultiDashboardAnalyticsServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-multi-dashboard-analytics`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}

export const getAllAlertsService = async () => {
    try {
        const response = await apiInterceptor.get("/get-all-alerts");
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getAccessPrevilageService = async () => {
    try {
        const response = await apiInterceptor.get("/groups/get-previlage-access");
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getUserRolesService = async () => {
    try {
        const response = await apiInterceptor.get("/get-user-roles");
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getHighRiskDevicesService = async (payload) => {
    try {
        const response = await apiInterceptor.get("/get-high-risk-devices", {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getAnomaliesService = async (payload) => {
    try {
        const response = await apiInterceptor.get("/get-anomaly-data", {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};