import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as React from 'react';
import ArrowDownIcon from '../../../static/svgs/ArrowDown';
import text from '../css/text.module.scss';
import tables from './table.module.scss';
export default function DrawerAccordion({ device }) {
    return (
        <>
            <Accordion defaultExpanded className={tables.accordion} >
                <AccordionSummary
                    className={tables.accordionSummary}
                    expandIcon={<ArrowDownIcon />}
                >
                    <p className={text.bold1}>System Details</p>
                </AccordionSummary>
                <AccordionDetails className={tables.accordionDetails}>
                    <p className={text.info2}>
                        CPU : {device?.systemDetails?.cpu}
                    </p>
                    <p className={text.info2}>
                        Os Version : {device?.systemDetails?.osVersion}
                    </p>
                </AccordionDetails>
            </Accordion>
            <Accordion className={tables.accordion} >
                <AccordionSummary
                    className={tables.accordionSummary}
                    expandIcon={<ArrowDownIcon />}
                >
                    <p className={text.bold1}>Network and Security Information</p>
                </AccordionSummary>
                <AccordionDetails className={tables.accordionDetails}>
                    <p className={text.info2}>
                        IP : {device?.systemDetails?.ip}
                    </p>
                    <p className={text.info2}>
                        Kernel : {device?.systemDetails?.kernel}
                    </p>
                </AccordionDetails>
            </Accordion>

        </>
    );
}