import { Box, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import styles from './multiDashboard.module.scss';
import theme from 'components/utils/theme';
import text from '../../shared/css/text.module.scss';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const GraphTitle = ({ title, showFullScreenBtn, handleClickFullScreen, showTimeMenu = true, menuOptions, setTime, time }) => {

    const handleChange = (event) => {
        setTime(event.target.value);
    };

    return (
        <Grid container display={'flex'} justifyContent={'center'}>
            <Grid className={styles.graphTitle} item xs={11}>
                <Typography className={text.heading2}>{title}</Typography>
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                    {showTimeMenu &&
                        <Select
                            value={time}
                            onChange={handleChange}
                            size='small'
                            // disableUnderline={true}
                            className={styles.selectInput}
                            sx={{
                                height: '30px',
                                borderRadius: '8px',
                                color: theme.palette.gray.main
                            }}
                        >
                            {menuOptions?.map((item, idx) => (<MenuItem key={idx} value={item?.value}>{item?.label}</MenuItem>))}
                        </Select>
                    }
                    {showFullScreenBtn &&
                        <IconButton sx={{ p: 0 }} onClick={handleClickFullScreen} >
                            <FullscreenIcon />
                        </IconButton>
                    }
                </Box>

            </Grid>
        </Grid>
    );
};

export default GraphTitle;
